import  { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app-context';
import { sendEmail, getBase64, IUploadPage, UploadPageContent } from '../StrapiService';
import SanitizeHTML from '../components/SanitizeHTML';
import { jsPDF } from "jspdf"
import { findDiagnosisByMaxProbability, getOrderDetails, IAiImageOrderByOrderId, IDiagnosisAiResult, API_HOST, WEB_HOST } from '../helpers';

function UploadResultPage() {
  const { t } = useTranslation();
  const appContext = useContext(AppContext)
  const loc = appContext.locale
  const [uploadPage, setUploadPage] = useState<IUploadPage | undefined>(undefined)
  const [photo, setPhoto] = useState<File>()
  const { publicId } = useParams<{ publicId?: string}>()
  const [orderDetails, setOrderDetails] = useState<IAiImageOrderByOrderId>()
  const [seconds, setSeconds] = useState(15)
  const pdfFont = appContext.locale === 'lt' ? 'Inter' : 'Helvetica'
  useEffect(() => {
    const interv = setInterval(() => {
      setSeconds(seconds => {
        console.log(seconds, orderDetails?.status)
        if (orderDetails?.status && (orderDetails.status === 'PendingPayment' || orderDetails.status === 'Processing' || orderDetails.status === 'ProcessingPayment')) {
          if (seconds <= 2) {
            clearInterval(interv)
            window.location.reload()
          }
        }
        if (orderDetails?.status && (orderDetails.status === 'Cancelled' || orderDetails?.status === 'Failed' || orderDetails?.status === 'Completed' || orderDetails?.status === 'Refunded')) {
          clearInterval(interv)
        }
        return seconds - 1
      })
    }, 1000)
    return () => {
      clearInterval(interv)
    }
  }, [orderDetails])

  useEffect(() => {
    console.log(publicId)
    if (publicId) {
      const noPhoto = () => {
        setPhoto(undefined)
        appContext.notify('No photo was found', 'normal')
      }
      getOrderDetails(publicId).then(od => {
        console.log(od)
        setOrderDetails(od)
        if (od?.imageUrl) {
          console.log(od.imageUrl)
          const url = od.imageUrl.includes(API_HOST) ? od.imageUrl : API_HOST + od.imageUrl
          fetch(url).then(res => res.blob()).then(blob => {
            setPhoto(new File([blob], "result_image.jpg"))
          }).catch(() => noPhoto())
        } else noPhoto()
      }).catch(() => noPhoto())
    }
  }, [publicId])
  const [emailAddress, setEmailAddress] = useState('')

  useEffect(() => {
    if (!uploadPage) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const ap = await UploadPageContent.getUploadPageContent(appContext.locale)
    setUploadPage(ap?.[0])
  }

  const resultTranslations = t('upload.result', { returnObjects: true, lng: appContext.locale }) as {
    title: string
    orderid: string
    diagnosis: string
    senddiagnosis: string
    emailaddress: string
    uploadanother: string
    emailsent: string
    otherdiagnoses: string
    madeonweb: string
    orderiscopied: string
    emailcontent : {
      testresults: string
      riskassessment: string
      conclusion: string
      treatment: string
      advice: string
      diagnosis: string
      filename: string
      otherdiagnoses: string
    }
    diagnoses : {
      [key: string]: {
        title: string
        message: string
        advice: string
      }
    }
  }

  const diagnosis = findDiagnosisByMaxProbability(orderDetails?.results)
  const diagnosisI18n = diagnosis ? resultTranslations.diagnoses[diagnosis.icdCode] : resultTranslations.diagnoses["none"];

  const probability = diagnosis ? diagnosis.probability : 0
  const results = <div className="result-content">
    <p>
      {resultTranslations.emailcontent.riskassessment}: {diagnosisI18n.message}
    </p>
    <p>
      {resultTranslations.emailcontent.conclusion}: <br />
      {diagnosisI18n.title}, {diagnosis?.icdCode.toUpperCase()} - {probability * 100}%
    </p>
    {orderDetails?.results && orderDetails.results?.slice(1).length > 0 &&
      <p>
        <span className='block'>
          {resultTranslations.otherdiagnoses}:
        </span>
        {orderDetails.results.slice(1).map(result => {
          return !!result.icdCode && !!appContext.locale && <span key={result.icdCode + result.probability}>
            {resultTranslations.diagnoses[result.icdCode].title}, {result.icdCode.toUpperCase()} - {result.probability * 100}%
          </span>
        })}
      </p>
    }
    <p>
      {/* NEXT: bring back if fields are needed */}
      {/* {resultTranslations.emailcontent.diagnosis}: {diagnosisI18n.diagnosis}<br />
      {resultTranslations.emailcontent.treatment}: {diagnosisI18n.treatment}<br /> */}
      {resultTranslations.emailcontent.advice}:
      <span className='block'>
        {diagnosisI18n.advice}
      </span>
    </p>
  </div>
  const resultsString = ReactDOMServer.renderToString(results)

  function stripHtml(html: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const generatePdfContent = async (doc: jsPDF) => {
    let step = 10
    let yStep = 20
    let xStep = 76
    doc.setFontSize(18);
    // dark blue
    doc.setTextColor(29,25,79);
    if (loc !== 'lt') doc.setFont(pdfFont, 'bold');
    doc.text(resultTranslations.title, xStep, yStep);yStep+=step*1.4
    doc.setFont(pdfFont, 'normal');
    // grey
    doc.setTextColor(143,141,165);
    doc.setFontSize(13);
    doc.text(new Date().toDateString(), xStep, yStep);yStep+=step/1.5
    doc.text(resultTranslations.orderid + ': ' + orderDetails?.publicId, xStep, yStep);yStep+=step*1.4
    doc.setFontSize(14);
    if (loc !== 'lt') doc.setFont(pdfFont, 'bold');
    // dark blue
    doc.setTextColor(29,25,79);
    doc.text(diagnosisI18n.title + ' - ' + probability * 100 + '%', xStep, yStep);yStep+=step
    // grey
    doc.setTextColor(143,141,165);
    if (diagnosis?.icdCode) {
      doc.setFont(pdfFont, 'normal');
      doc.text(resultTranslations.diagnoses[diagnosis.icdCode].message, xStep, yStep);yStep+=step/2
      
      if (orderDetails?.results) {
        let i = 0
        for (const result of orderDetails?.results) {
          if (i > 10) break;
          if (i === 0) {
            yStep+=step/2
            // dark blue
            doc.setTextColor(29,25,79);
            doc.text(resultTranslations.otherdiagnoses, xStep, yStep);yStep+=step/1.5
          }
          if (i !== 0) {
            // grey
            doc.setTextColor(143,141,165);
            doc.setFontSize(11);
            doc.text(resultTranslations.diagnoses[result.icdCode].title + ' - ' + result.probability*100 + '%', xStep, yStep);yStep+=step/2
          }
          i++
        }
      }
      yStep+=step

      doc.setFontSize(13);
      doc.setTextColor(29,25,79);
      if (loc !== 'lt') doc.setFont(pdfFont, 'bold');
      doc.text(resultTranslations.emailcontent.advice, xStep, yStep);yStep+=step
      doc.setFont(pdfFont, 'normal');
      doc.setTextColor(143,141,165);
      const splitMessage = doc.splitTextToSize(resultTranslations.diagnoses[diagnosis.icdCode].advice, 122);
      doc.text(splitMessage, xStep, yStep);yStep+=step*splitMessage.length
    }
    if (uploadPage?.disclaimer) {
      if (loc !== 'lt') doc.setFont(pdfFont, 'italic');
      doc.setFontSize(11);
      const splitMessage2 = doc.splitTextToSize(stripHtml(uploadPage.disclaimer), 122);
      doc.text(splitMessage2, xStep, yStep);yStep+=step*splitMessage2.length*0.7
    }

    const locale = loc === 'en' ? '' : 'lt/'
    doc.setFont(pdfFont, 'normal');
    if (orderDetails?.publicId) {
      doc.setFontSize(11);
      doc.text(`${resultTranslations.madeonweb}: ${WEB_HOST}/${locale}ai-result/${orderDetails?.publicId}`, 15, 285);
    }
      

    if (photo) {
      doc.addImage((await getBase64(photo)) as string, 'base64', 15, step*1.5, 50, 50)
    }
    doc.save(resultTranslations.emailcontent.filename);
    return doc
  }

  const generatePdf = async () => {
    let doc = new jsPDF();
    if (appContext.locale === 'lt') {
      // font is huge, so import only on LT language, because it is needed for baltic encoding, jsPDF only has latin if fonts are not imported
      // lazy load, font should be shaken off if not used...
      import('../Inter-font').then(async font => {
        doc.addFileToVFS('Inter.ttf', font.default);
        doc.addFont("Inter.ttf", pdfFont, "normal");
        doc.setFont(pdfFont, "normal");
        doc = await generatePdfContent(doc)
      })
    }
    else {
      doc.addFont(pdfFont, pdfFont, "normal");
      doc.setFont(pdfFont, "normal");
      doc = await generatePdfContent(doc)
    }
  }
  const createContactUsField = () => {
    const colorClass = orderDetails?.status === 'Cancelled' || orderDetails?.status === 'Failed' ? 'text-error' : undefined
    return <p className='contact-us-message'>
      {orderDetails?.publicId &&
        <p className='mt-1 text-small'>
          <span className={colorClass}>
            {t('upload.result.ordernotfulfilled')}.<br />
          </span>
            <span className={'copy-text-field text-normal' + (colorClass ? ' ' + colorClass : '')} onClick={async () => {
              if (orderDetails.publicId && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(resultTranslations.orderid + ': ' + orderDetails.publicId)
                appContext.notify(resultTranslations.orderiscopied, 'success')
              }
            }}>{t('upload.result.clickcopyorderid')}: {orderDetails.publicId}</span>
        </p>
      }
      {!orderDetails?.publicId &&
        <>
          {t('upload.result.ordernotfulfilled_nodata')}.<br />
          <a className='block' href="mailto:users@smartvissolution.com">users@smartvissolution.com</a>
        </>
      }
    </p>
  }

  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="ai-result-page presentation-page flex column align-center">
          <div className="max-article px-sm-1">
            <article className="mt-2">
              <h1>
                {t('upload.result.title')}
              </h1>
              {orderDetails?.updatedAt &&
                <h2>{new Date(orderDetails.updatedAt).toDateString()}</h2>
              }
              {orderDetails?.publicId &&
                <h2 className="mb-3 mb-sm-2">{t('upload.result.orderid')}: {orderDetails.publicId}</h2>
              }
              <div className="flex">
                {orderDetails?.status === 'Completed' &&
                  <>
                    <div className="flex column align-center">
                      {photo &&
                        <img className="upload-mole" src={URL.createObjectURL(photo)} alt="" />
                      }
                      <div className="print-download-buttons text-center mt-2">
                        <div className="">
                          <button className="btn btn-primary" onClick={async () => {
                            await generatePdf()
                          }}>
                            {t('download')}
                          </button>
                          <button className="btn btn-outline mt-05" onClick={() => window.print()}>
                            {t('print')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow ml-4">
                      {results}
                      <div className="email-block">
                        <h3 className="mt-2">
                          {t('upload.result.senddiagnosis')}
                        </h3>
                        <div className="flex send-email py-1">
                          <input onChange={(element) => setEmailAddress(element.currentTarget.value)} placeholder={resultTranslations.emailaddress} type="text" className="flex-grow input-field" id="ai-result-email" />
                          <button className="btn btn-primary ml-1" onClick={async () => {
                            const sent = await sendEmail(emailAddress, resultsString, photo)
                            if (sent) {
                              appContext.notify(resultTranslations.emailsent, 'success')
                              ;(document.getElementById('ai-result-email') as HTMLInputElement).value = ''
                            }
                            else appContext.notify('Email was not sent, please inform administrator', 'error')
                          }}>{t('send')}</button>
                        </div>
                      </div>
                      <i>
                        <SanitizeHTML html={uploadPage?.disclaimer ?? ''} />
                      </i>
                      <div className="again-block flex column">
                        <Link className="block mt-2 mb-05" to="/upload-photo">
                          {t('upload.result.uploadanother')}
                        </Link>
                        <Link className="block mt-05 mb-3" to="/">
                          {t('gohome')}
                        </Link>
                      </div>
                    </div>
                  </>
                }
                {orderDetails?.status !== 'Completed' &&
                  <div className="flex align-center minw-100">
                    <div className='text-center minw-100'>
                      {photo &&
                        <div className='minw-100 mb-1'>
                          <img className=" upload-mole" src={URL.createObjectURL(photo)} alt="" />
                        </div>
                      }
                      {(!orderDetails?.status || orderDetails.status === 'PendingPayment' || orderDetails.status === 'Processing' || orderDetails.status === 'ProcessingPayment') &&
                        <>
                          {t('upload.result.waitingpaymentresults')}
                          {createContactUsField()}
                          {t('upload.result.pagewillreload')} {seconds}s<br />
                          <button className='btn mt-1 mb-3 btn-primary' onClick={() => {
                            window.location.reload()
                          }}>{t('upload.result.reloadpage')}</button>
                        </>
                      }
                      {orderDetails?.status === 'Refunded' &&
                        <>
                          {t('upload.result.paymentrefunded')}.<br />
                          {createContactUsField()}
                          <button className='btn mt-1 mb-3 btn-primary' onClick={() => {}}>{t('gohome')}</button>
                        </>
                      }
                      {orderDetails?.status === 'Cancelled' &&
                        <>
                          <span className='text-error'>
                            {t('upload.result.paymentcancelled')}.<br />
                          </span>
                          {createContactUsField()}
                          <Link className='btn mt-1 mb-3 btn-primary' to="/">{t('gohome')}</Link>
                        </>
                      }
                      {orderDetails?.status === 'Failed' &&
                        <>
                        <span className='text-error'>
                          {t('upload.result.paymentfailed')}.<br />
                        </span>
                        {createContactUsField()}
                        <Link className='btn mt-1 mb-3 btn-primary' to="/">{t('gohome')}</Link>
                      </>
                      }
                    </div>
                  </div>
                }
              </div>
            </article>
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default UploadResultPage
