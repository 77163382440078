import React from 'react'
import {Stripe} from '@stripe/stripe-js';

export type Locales = 'en' | 'lt'
export type NotificationType = 'normal' | 'success' | 'error'

interface IAppContext {
  locale: Locales,
  stripePromise?: Promise<Stripe | null>,
  notify: (message: string, notificationType: NotificationType) => void
}


export const appContext: IAppContext = {
  locale: 'en',
  notify: () => {}
  // down bellow is user, etc.
}

export const AppContext = React.createContext(
  appContext
)
