import { Suspense } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import PageWrapper from './components/PageWrapper';
import ArticlePage from './pages/ArticlePage';
import ArticlesListPage from './pages/ArticlesListPage';
import ContactUsPage from './pages/ContactUsPage';
import ProductsPage from './pages/ProductsPage';
import ProductsPageExample from './pages/ProductsPageExample';
import UploadPhotoPage from './pages/UploadPhotoPage';
import UploadPayPage from './pages/UploadPayPage';
import UploadResultPage from './pages/UploadResultPage';
import Page404 from './pages/Page404';
import FaqPage from './pages/FaqPage';
import PolicyPage from './pages/PolicyPage';
// import TermsPage from './pages/TermsPage';

function App() {
  const base = '/:locale?';
  return (
    <Router>
      <PageWrapper>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path={base} element={<HomePage />} />
            <Route path={`${base}/upload-photo`} element={<UploadPhotoPage />} />
            <Route path={`${base}/upload-pay`} element={<UploadPayPage />} />
            <Route path={`${base}/ai-result/:publicId`} element={<UploadResultPage />} />
            <Route path={`${base}/products`} element={<ProductsPage />} />
            <Route path={`${base}/products-example`} element={<ProductsPageExample />} />
            <Route path={`${base}/contact-us`} element={<ContactUsPage />} />
            <Route path={`${base}/blog/:slug`} element={<ArticlePage />} />
            <Route path={`${base}/blog`} element={<ArticlesListPage />} />
            <Route path={`${base}/faq`} element={<FaqPage />} />
            <Route path={`${base}/privacy-policy`} element={<PolicyPage />} />
            <Route path="*" element={<Page404 />} />;
          </Routes>
        </Suspense>
      </PageWrapper>
    </Router>
  );
}

export default App;

