import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app-context';
import { IArticle } from '../StrapiService'
import ContactUsMap from '../components/ContactUsMap';
// AIzaSyAPHOlsb_sAeRbg0A4FaWijdBEqCt-DOeg
interface State {
  articles: IArticle[]
  articlesCount: number
}

function ContactUsPage() {
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="contact-us-page flex column align-center h-100">
          <div className="maps d-block w-100 h-100">
            <ContactUsMap />
          </div>
          <div className="flex column align-center w-100 h-100 bg-footer">
            <div className="max-content h-100 flex column px-sm-1 px-md-2 pt-3">
              <h1 className="mb-1">{t('contacts.title')}</h1>
              <p>
                {t('contactspage.message')}
              </p>
              <div className="flex contacts row">
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block">{t('contacts.company')}</strong>
                  </div>
                  <div>
                    {t('contacts.address1')}
                  </div>
                  <div>
                    {t('contacts.address2')}
                  </div>
                  <div className="pb-1">
                    {t('contacts.country')}
                  </div>
                  <div className="capitalize">
                    {t('phoneshort')}: {t('contacts.phone')}
                  </div>
                  <div>
                    <span className="capitalize">{t('email')}</span>: <a href={`mailto:info@dts-solutions.lt`}>
                      {t('contacts.email')}
                    </a>
                  </div>
                </div>
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block">{t('contactspage.questionsMessage')}</strong>
                  </div>
                  <div>
                    {t('contactspage.questionsSentence')}
                  </div>
                  <div>
                    <span className="capitalize">{t('email')}</span>: <a href={`mailto:users@smartvissolution.com`}>
                      {t('contactspage.faqEmail')}
                    </a>
                  </div>
                  <div className="mt-2">
                    <Link className="btn btn-primary" to="/faq">
                      {t('contactspage.faqButton')}
                    </Link>
                  </div>
                </div>
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block">{t('contactspage.pressMessage')}</strong>
                  </div>
                  <div className="capitalize">
                    {t('phoneshort')}: {t('contacts.phone')}
                  </div>
                  <div>
                    <span className="capitalize">{t('email')}</span>: <a href={`mailto:linas.eidimtas@dts-solutions.lt`}>
                      {t('contactspage.pressEmail')}
                    </a>
                  </div>
                  <div className="mt-2">
                    <Link className="btn btn-outline" to="/press-kit">{t('contactspage.pressKit')}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default ContactUsPage
