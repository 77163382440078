import { IFaqPage } from './types';
import StrapiSDK from './StrapiSDK'

export const PAGE_ENDPOINT = `faqpage`

export class FaqPageContent {
  static async getFaqPageContent(locale: string) {
    const p = await StrapiSDK.getEntries(`${PAGE_ENDPOINT}?_locale=${locale}`) as IFaqPage[]
    return (Array.isArray(p) ? p[0] : p) as IFaqPage
  }
}
