import { ChangeEventHandler } from 'react';
type Props = {
  label:string,
  id:string,
  type:string,
  placeholder:string,
  required:any,
  autoComplete:string,
  value:string,
  onChange: ChangeEventHandler
}

function InputField({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange
}: Props) {
  return (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="input-field"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default InputField
