import { IUploadPage } from '.';
import StrapiSDK from './StrapiSDK'
import { IAboutPage } from './types'

export const ENDPOINT = `uploadpage`

export class UploadPageContent {
  static async getUploadPageContent(locale: string) {
    const p = await StrapiSDK.getEntries(`${ENDPOINT}?_locale=${locale}`) as IUploadPage[];
    return Array.isArray(p) ? p : [p]
  }
}
