import StrapiSDK from './StrapiSDK'
import { IGeneralData } from './types'

const ENDPOINT = `emails`

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        // const base64String = result.replace('data:', '').replace(/^.+,/, '');
        const base64String = result.split("base64,")[1]
        console.log(base64String);
        resolve(base64String);
      }
    };
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  });
}

export const sendEmail = async (email: string, content: string, attachment?: File) => {
  const xhr = new XMLHttpRequest();
  const promise = new Promise(async (resolve) => {
    let attachmentString = ''
    if(attachment) {
      attachmentString = await getBase64(attachment) as string
    }
    xhr.open("POST", StrapiSDK.strapiUrl + ENDPOINT, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
          email,
          attachment: attachmentString,
          content,
      }));
      xhr.onreadystatechange = (ev) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // console.log(xhr.responseText)
            resolve(true)
          } else {
            resolve(false)
            console.log("Error", xhr.statusText);
          }
      }}
  })
  return promise
}