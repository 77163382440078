import { IPolicyPage } from './types';
import StrapiSDK from './StrapiSDK'

const PAGE_ENDPOINT = `policy-page`

export class PolicyPageContent {
  static async getPolicyPageContent(locale: string) {
    const p = await StrapiSDK.getEntries(`${PAGE_ENDPOINT}?_locale=${locale}`) as IPolicyPage[];
    return (Array.isArray(p) ? p[0] : p) as IPolicyPage
  }
}
