import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useTranslation } from 'react-i18next';

const ContactUsMap = () => {
  const { t } = useTranslation();
  return (
    <MapContainer style={{height: '100%', width: '100%'}} center={[54.917004, 23.987139]} zoom={13} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[54.917004, 23.987139]}>
        <Popup>
          <strong>
            {t('contacts.company')}
          </strong>
          <div>
            {t('contacts.address1')}
          </div>
          <div>
            {t('contacts.address2')}
          </div>
          <div>
            {t('contacts.country')}
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default ContactUsMap


