import StrapiSDK from './StrapiSDK'
import { IHomePage } from './types'

export const HOMEPAGE_ENDPOINT = `homepage`

export class HomePageArticle {
  static async getHomePageArticle(locale: string) {
    const hp = await StrapiSDK.getEntries(`homepage?_locale=${locale}`) as IHomePage[];
    return Array.isArray(hp) ? hp : [hp]
  }
  static getArticleExerpt(content?: string, charsCount:number = 250) {
    if (content) return content.replace(/<[^>]*>?/gm, '').slice(0, charsCount) + "..."
    return ''
  }
}
