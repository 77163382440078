import React, { useState } from 'react';

interface Props {
  positionsCount: number
  changePosition: Function
}

function PrevNextButtons({positionsCount, changePosition}: Props) {
  const [position, setPosition] = useState(0)
  const setNextPosition = () => {
    let newPosition = position + 1
    if (newPosition > positionsCount - 1) newPosition = 0
    setPosition(newPosition)
    changePosition(newPosition)
  }
  const setPrevPosition = () => {
    let newPosition = position - 1
    if (newPosition < 0) newPosition = positionsCount - 1
    setPosition(newPosition)
    changePosition(newPosition)
  }
  return (
    <div className="prev-next mt-1">
      <img src="/picts/svgs/chevron-left.svg" alt="" onClick={() => setPrevPosition()} />
      <img src="/picts/svgs/chevron-right.svg" alt="" onClick={() => setNextPosition()} />
    </div>
  )
}

export default PrevNextButtons
