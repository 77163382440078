import { useContext, useEffect, useRef, useState } from 'react';
import { IUploadPage, UploadPageContent } from '../StrapiService'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app-context';
import { PhotoDropzone } from '../components/PhotoDropzone';
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop'

function dataURLtoFile(dataurl:string, filename:string) {
  const arr = dataurl.split(',')
  const mime = arr?.[0].match(/:(.*?);/)?.[1],
  bstr = atob(arr[1])
  let n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
function UploadPhotoPage() {
  const { t } = useTranslation();
  const appContext = useContext(AppContext)
  const [uploadPage, setUploadPage] = useState<IUploadPage | undefined>(undefined)
  const [photo, setPhoto] = useState<File>()
  const navigate = useNavigate();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.5)
  const [cropPhoto, setCropPhoto] = useState<File>()

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    if (photo) {
      const img = new Image()
      img.addEventListener('load', e => {
        const canvas = document.createElement("canvas")
        canvas.width = croppedAreaPixels.width
        canvas.height = croppedAreaPixels.height
        const ctx = canvas.getContext("2d")
        ctx?.drawImage(
          img,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height
        )
        const fileStr = canvas.toDataURL('image/jpeg')
        const file = dataURLtoFile(fileStr, photo.name)
        if (cropPhoto?.size !== file.size) {
          setCropPhoto(file)
        }
      })
      img.src = URL.createObjectURL(photo)
    }
  }

  useEffect(() => {
    if (!uploadPage) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const ap = await UploadPageContent.getUploadPageContent(appContext.locale)
    setUploadPage(ap?.[0])
  }

  const handleFileUpload = (file?: File) => {
    setPhoto(file)
  }

  const fileInput = useRef<HTMLInputElement>(null);
  
  return (
    <div className="presentation-page upload-photo-page flex column align-center">
      <div className="max-article" style={{position: 'relative'}}>
        <div className="px-sm-2 px-md-2">
          <div className="flex mt-1 mx-2">
            <article className="upload-sticky justify-content-center text-center">
              <h1>{t('uploadphoto')}</h1>
              <h2 className="mt-05">{t('upload.photo.getimmediately')}</h2>
              <div className="my-3">
                {!photo &&
                  <PhotoDropzone photoCallback={handleFileUpload}>
                    <img src="/picts/svgs/upload-camera.svg" alt="" style={{pointerEvents: 'none'}} />
                  </PhotoDropzone>
                }
                {photo &&
                  <div style={{display:'inline-block', position: 'relative', alignSelf: 'center', width: 400, height: 400, overflow: 'hidden', borderRadius: 20}}>
                    <Cropper
                      image={URL.createObjectURL(photo)}
                      crop={crop}
                      zoom={zoom}
                      showGrid={false}
                      aspect={1 / 1}
                      onCropChange={(size) => {
                        // infinite loop without conditioning
                        if (size.x !== crop.x || size.y !== crop.y) {
                          setCrop(size)
                        }
                      }}
                      onCropComplete={onCropComplete}
                      onZoomChange={(z) => {
                        // infinite loop without conditioning
                        if (z !== zoom) {
                          setZoom(z)
                        }
                      }}
                      cropSize={{height: 360, width: 360}}
                    />
                  </div>
                }
              </div>
              <input ref={fileInput} type="file" id="myFile" name="filename" onChange={(e) => handleFileUpload(e.target.files?.[0])} style={{display: 'none'}} />
              {!photo &&
                <>
                  <button onClick={() => fileInput.current?.click()} className="btn btn-big btn-inline btn-primary">
                    {t('uploadphoto')}
                  </button>
                  <div className="upload-photo-text mx-6 mt-2">
                    {t('upload.photo.message')}
                  </div>
                </>
              }
              {photo &&
                <>
                  <button onClick={() => {
                    navigate((appContext.locale !== 'en' ? '/' + appContext.locale : '') + '/upload-pay', { state: { photo: cropPhoto ?? photo } })
                  }} className="btn btn-big btn-inline btn-primary">
                    {t('upload.photo.crop')}
                  </button>
                  <div className="upload-photo-text mx-6 mt-2">
                    {t('upload.photo.cropmessage')}
                  </div>
                </>
              }
              <div className="content mb-5">
              </div>
            </article>
            <article className="upload-how-to">
              <h4>
                {t('upload.photo.howtotitle')}
              </h4>
              <div className="content mb-4 mt-3">
                {uploadPage?.takePictureStep.map((tp, index) =>
                  <div key={"tp-"+index} className="flex mb-6">
                    {tp.image && tp.image.url &&
                      <div className="mr-2">
                        <img className="article-main-image" src={process.env.REACT_APP_STRAPI_URL + tp.image.url} alt="" />
                      </div>
                    }
                    <div className="flex-grow">
                      <h5>{tp.title}</h5>
                      <p>
                        {tp.text}
                      </p>
                    </div>
                  </div>)
                }
              </div>
              <div className="content mb-5">
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadPhotoPage
