import React, { PureComponent, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  itemsCount: number
  currentPage: number
  pageItemsLimit: number
  path: string
  showMaxPages: number
}

class PaginationIterator extends PureComponent<Props> {
  active(item: number) {
    return item === this.props.currentPage
  }

  get pageMax() {
    return Math.ceil(this.props.itemsCount / this.props.pageItemsLimit)
  }

  get pagePrev() {
    return this.props.currentPage - 1 > 0 ? this.props.currentPage - 1 : 0
  }

  get pageNext() {
    return this.props.currentPage + 1 <= this.pageMax + 1 ? this.props.currentPage + 1 : this.pageMax + 1
  }

  get paginationFirst() {
    return (
      <li key="first" className={`page-item${ this.pagePrev <= 0 ? ' disabled' : ''}`}>
        <Link className="page-link" to={`/${this.props.path}?page=1`}>«</Link>
      </li>
    )
  }

  get paginationLast() {
    return (
      <li key="last" className={`page-item${ this.pageNext > this.pageMax ? ' disabled' : ''}`}>
        <Link className="page-link" to={`/${this.props.path}?page=${this.pageMax}`}>»</Link>
      </li>
    )
  }

  get paginationPrev() {
    return (
      <li key="prev" className={`page-item${ this.pagePrev <= 0 ? ' disabled' : ''}`}>
        <Link className="page-link" to={`/${this.props.path}?page=${this.pagePrev}`}>
          <img src="/picts/svgs/chevron-left.svg" alt="" />
        </Link>
      </li>
    )
  }

  get paginationNext() {
    return (
      <li key="next" className={`page-item${ this.pageNext > this.pageMax ? ' disabled' : ''}`}>
        <Link className="page-link" to={`/${this.props.path}?page=${this.pageNext}`}>
          <img src="/picts/svgs/chevron-right.svg" alt="" />
        </Link>
      </li>
    )
  }

  get paginationEmptyLeft () {
    return (
      <li key="empty-left" className="page-item disabled">
        <span className="page-link">…</span>
      </li>
    )
  }

  get paginationEmptyRight () {
    return (
      <li key="empty-right" className="page-item disabled">
        <span className="page-link">…</span>
      </li>
    )
  }

  get paginationItems() {
    const paginationItems: JSX.Element[] = []
    let first = this.props.currentPage
    let last = this.props.currentPage
    if (first > this.pageMax) first = last = 1
    for (let i = this.props.showMaxPages - 1; i > 0; i--) {
      if (i%2 === 0 && (first - 1 > 0) || last >= this.pageMax) {
        first -= 1
      } else last += 1
    }
    if (first < 1) first = 1
    if (last > this.pageMax) last = this.pageMax
    for (let item = first; item <= last; item++) {
      paginationItems.push(
        <li key={item} className={`page-item${ this.active(item) ? ' active' : ''}`}>
          <Link className="page-link" to={`/${this.props.path}?page=${item}`}>
          {item}</Link>
        </li>,
      );
    }
    return (
      <>
      {
        first > 1 &&
        this.paginationEmptyLeft
      }
      {
        paginationItems
      }
      {
        last < this.pageMax &&
        this.paginationEmptyRight
      }
      </>
    )
  }

  render(): ReactNode {
    return (
      <>
      {
        this.pageMax > 1 &&
        <ul className="pagination" key={this.props.currentPage}>
          {
            this.pageMax > this.props.showMaxPages &&
            this.paginationFirst
          }{
            this.paginationPrev
          }{
            this.paginationItems
          }{
            this.paginationNext
          }{
            this.pageMax > this.props.showMaxPages &&
            this.paginationLast
          }
        </ul>
      }
      </>
    )
  }
}

export default PaginationIterator
