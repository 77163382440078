import StrapiSDK from './StrapiSDK'
import { IGeneralData } from './types'

export const GENERAL_ENDPOINT = `general`

let _generalData: IGeneralData[] | undefined = undefined

export class GeneralData {
  static async getGeneralData(locale: string) {
    if (_generalData) return _generalData
    const hp = await StrapiSDK.getEntries(`${GENERAL_ENDPOINT}?_locale=${locale}`) as IGeneralData[];
    _generalData = Array.isArray(hp) ? hp : [hp]
    return _generalData
  }
}
