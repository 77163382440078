import React, { PureComponent, ReactNode } from 'react'
import sanitizeHtml from 'sanitize-html'

interface Props {
  html: string
  options?: Partial<sanitizeHtml.IOptions>
  className?: string
}

class SanitizeHTML extends PureComponent<Props> {
  
  defaultOptions: Partial<sanitizeHtml.IOptions> = {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'ol', 'ul', 'li', 'h1', 'h2', 'img' ],
    allowedAttributes: {
      'a': [ 'href' ],
      'img': [ 'src', 'caption', 'alt' ],
    },
    allowedIframeHostnames: ['www.youtube.com']
  }

  sanitize = (dirty: string, options: Partial<sanitizeHtml.IOptions>) => ({
    __html: sanitizeHtml(
      dirty,
      { ...this.defaultOptions, ...options }
    )
  })

  render(): ReactNode {
    const options = this.props.options ? this.props.options : {}
    return (
      <div className={this.props.className ? this.props.className : undefined} dangerouslySetInnerHTML={this.sanitize(this.props.html, options)} />
    )
  }
}

export default SanitizeHTML
