import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext, AppContext } from '../app-context';
import { IAiImageOrderByOrderId, preparePayment, setPaymentData } from '../helpers';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../components/CheckoutForm';

type StateProps = {
  photo?: File
}

function UploadPayPage() {
  const { t } = useTranslation();
  const imageRef = useRef<HTMLImageElement>(null)
  const context = useContext(AppContext)
  const navigate  = useNavigate()
  const location = useLocation()
  const state: StateProps = location.state
  const price = 1 // EUR, later multiple currencies
  const [options, setOptions] = useState<{clientSecret?: string}>()
  const [paymentDetails, setPaymentDetails] = useState<IAiImageOrderByOrderId>()

  useEffect(() => {
    if (state.photo && imageRef?.current && price > 0) {
      const prepare = async (photo: File) => {
        const pd = await preparePayment(photo)
        console.log('pd', pd)
        setPaymentDetails(pd)
        const clientSecret = pd?.payments.Stripe.clientSecret
        if (clientSecret) {
          console.log('secret', clientSecret)
          console.log(appContext.stripePromise)
          setOptions({
            clientSecret: clientSecret
          })
        } else {
          setOptions(undefined)
        }
      }
      
      prepare(state.photo)
      imageRef.current.src = URL.createObjectURL(state.photo)
    }
  }, [imageRef, state, price])

  const onPayComplete = async () => {
    context.notify('Payment successful', 'success')
    await setPaymentData(paymentDetails?.id ?? "", "Stripe")
    if (state.photo) {
      navigate((context.locale !== 'en' ? '/' + context.locale : '') + '/ai-result/' + paymentDetails?.publicId)
    }
  }

  return (
    <div className="upload-pay-page presentation-page flex column align-center">
      <div className="max-article px-sm-1">
        <article className="mt-2">
          <h1>
            {t('upload.pay.title')}
          </h1>
          <h2 className="mb-3 mb-sm-2">{t('upload.pay.subtitle')}</h2>
          <div className="flex">
            <div className="text-center">
              {imageRef &&
                <img className="upload-mole" ref={imageRef} alt="" />
              }
              <div className="text-center mt-2">
                {t('upload.pay.badpicture')}
                <div>
                  <Link to="/upload-photo">
                    {t('upload.pay.another')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex column flex-grow ml-4">
              <h3>{t('upload.pay.onephoto')}</h3>
              <p className="flex-grow">
                {t('upload.pay.onephotomessage')}&nbsp;
                {t('upload.pay.savemoneymessage')}
              </p>
              {paymentDetails?.id &&
                <div className="flex justify-content-end">
                  <div className="pay-block text-center">

                  </div>
                </div>
              }
            </div>
          </div>
          <div className="payment-options bg-info-block-lighten-3 my-3 px-2 pt-3 pb-2">
            <h2 className="mb-2">
              {t('upload.pay.paywithcard')}
            </h2>
            {state.photo && options && options.clientSecret &&
              <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY!)} options={options}>
                <CheckoutForm onPayComplete={onPayComplete} clientSecret={options.clientSecret} />
              </Elements>
            }
            {state.photo && !options?.clientSecret &&
              <div className='mb-2'>
                {t('upload.pay.paywithcard_unavailable')}
              </div>
            }
          </div>
          {/* NEXT: show this block and set up subscriptions */}
          {/* <div className="payment-options bg-info-block-lighten-3 my-3 px-2 pt-3 pb-2">
            <h2>
              {t('upload.pay.freefull')}
            </h2>
            <p>
              {t('upload.pay.savemoneymessage')}
            </p>
            <Link className="btn btn-outline-grey small" to="/">
              {t('upload.pay.compare')}
            </Link>
          </div> */}
        </article>
      </div>
    </div>
  )
}

export default UploadPayPage
