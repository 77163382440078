import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app-context';
import { GeneralData, IGeneralData } from '../StrapiService';

interface Params {
  slug?: string
}

function Page404() {
  const { t } = useTranslation();
  const appContext = useContext(AppContext)

  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="article-page flex column align-center">
          <div className="mt-2 text-center">
            {t('page404')}<br /><br />
            <Link to="/">
              {t('gohome')}
            </Link>
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default Page404
