import StrapiSDK from './StrapiSDK'
import { IAboutPage } from './types'

export const ABOUTPAGE_ENDPOINT = `about`

export class AboutPageContent {
  static async getAboutPageContent(locale: string) {
    const p = await StrapiSDK.getEntries(`${ABOUTPAGE_ENDPOINT}?_locale=${locale}`) as IAboutPage[];
    return Array.isArray(p) ? p : [p]
  }
}
