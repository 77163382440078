import Strapi from 'strapi-sdk-js';

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL as string + '/';
const _sdk = new Strapi({
  url: STRAPI_URL,
  prefix: '',
  // headers: {
  //   Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
  // }
});

export class StrapiSDK {
  static get sdk() {
    return _sdk
  }
  static get strapiUrl() {
    // later it will be env var or other solution
    return STRAPI_URL
  }
  static async getEntries(contentTypePluralized: string, params?: any) {
    try {
      return await this.sdk.find(contentTypePluralized, params)
    } catch(e) {
      console.error('SmartVisSolution - bad or no response from the cms server, please check the connection, request, roles or reload page.')
      return []
    }
  }
  static async getEntry(contentTypePluralized: string, id: string) {
    try {
      return await this.sdk.findOne(contentTypePluralized, id)
    } catch(e) {
      console.error('SmartVisSolution - bad or no response from the cms server, please check the connection, request, roles or reload page.')
      return
    }
  }
  // static async getEntryCount(contentType: string) {
  //   try {
  //     return await this.sdk.count(contentType)
  //   } catch(e) {
  //     console.error('SmartVisSolution - bad or no response from the cms server, please check the connection, request, roles or reload page.')
  //     return
  //   }
  // }
}

export default StrapiSDK
