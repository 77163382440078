import { useContext, useEffect, useState } from 'react';
import { IHomePage, HomePageArticle, IGeneralData, GeneralData } from '../StrapiService'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app-context';

function ProductsPageExample() {
  const { t } = useTranslation();
  const appContext = useContext(AppContext)
  const [homePage, setHomePage] = useState<IHomePage | undefined>(undefined)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!homePage) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const hp = await HomePageArticle.getHomePageArticle(appContext.locale)
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setHomePage(hp?.[0])
    setGeneralData(gd?.[0])
  }
  
  return (
    <div className="presentation-page products-page flex column align-center">
      <div className="jumbotron flex column align-center text-center">
        <h1 className="mt-3 mt-sm-4  px-sm-2 px-md-2">
          About SmartVisSolution
        </h1>
        <h2 className="mt-1 mb-3 px-sm-2 px-md-2">Software and hardware solutions for seamless diagnostics</h2>
        <div className="presentation">
          <img className="presentation-img" src="/picts/pngs/products-page-main.png" alt="Admin theme" />
        </div>
        <div className="bottom">
          <img src="/picts/svgs/index-jumbo-bottom.svg" alt="" />
        </div>
        <div className="dots-bg">
          <img src="/picts/jpgs/products-page-main-bg.jpg" alt="AI and human" />
        </div>
      </div>
      <div className="max-article">
        <div className="max-content px-sm-2 px-md-2">
          <div className="content flex row mt-1 align-start">
            <article>
              <div className="content mb-4">
                <blockquote>
                  One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                </blockquote>
                <p>
                  "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.
                </p>
                <p>
                  It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops
                </p>
                <p>
                  One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                </p>
                <h2>Some Table if needed</h2>
                <table>
                  <tr>
                    <th style={{width: 180}}>Feature</th>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td>Blue diode</td>
                    <td>
                      The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                    </td>
                  </tr>
                  <tr>
                    <td>Blue diode</td>
                    <td>
                      The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                    </td>
                  </tr>
                  <tr>
                    <td>Blue diode</td>
                    <td>
                      The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                    </td>
                  </tr>
                </table>
                <p>
                  It showed a lady fitted <a href="#">out with a fur</a> hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops
                </p>
                <p>
                  One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.
                </p>
              </div>
              <div className="features columns-2 columns-sm-1 mb-2">
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-ai.svg" alt="" /><span className="ml-1">{t('about.icons.aidiagnoses')}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-lens.svg" alt="" /><span className="ml-1">{t('about.icons.lenssupport')}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-consultations.svg" alt="" /><span className="ml-1">{t('about.icons.econsultations')}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-moleautopos.svg" alt="" /><span className="ml-1">{t('about.icons.moleautopos')}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-dicom-hl7.svg" alt="" /><span className="ml-1">{t('about.icons.dicomhl')}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-wave-modes.svg" alt="" /><span className="ml-1">{t('about.icons.multimode')}</span>
                </div>
              </div>
              <div className="content mb-5">
                <p>
                  "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.
                </p>
                <p>
                  It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops
                </p>
              </div>
            </article>
          </div>
          <div className="download mb-5 text-center">
            <Link to="/login" className="btn btn-big btn-inline btn-primary">
              {t('getstarted')}
            </Link>
            <div className="platforms mt-05 text-small">
              {t('jumbotron.download.message')} <Link to="/login">
                {t('web')}
              </Link>, {generalData?.appstorelink && <a href={generalData?.appstorelink}>iOS</a>}, {generalData?.googleplaylink && <a href={generalData?.googleplaylink}>Android</a>}
            </div>
            <img className="w-100" src="/picts/jpgs/mobile-horizontal-presentation.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPageExample
