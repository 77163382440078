const staticData = {
  // iOSDownloadLink: 'https://www.smartvissolution.com/download/ios',
  // androidDownloadLink: 'https://www.smartvissolution.com/download/android',
  // facebookLink: 'https://www.facebook.com/smartvissolution',
  // instagramLink: 'https://www.instagram.com/smartvissolution',
  // twitterLink: 'https://www.twitter.com/smartvissolution',
  dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
}


export default staticData;
